import React from "react";

import PropTypes from "prop-types";

import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class Map extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const hasWindow = typeof window !== 'undefined' && window

    if(!hasWindow) { return; }

    mapboxgl.accessToken =
      "pk.eyJ1IjoiYW1haG9uIiwiYSI6ImNpcmgzejZyeDAxOTFnNm5yZXVocDNvcjYifQ.HbwFh7tdIgCDvyyBFOHQFA";

    const _this = this;

    const _map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.props.style,
      center: this.props.center,
      zoom: this.props.zoom,
      pitch: this.props.pitch,
      bearing: this.props.bearing,
    });

    var nav = new mapboxgl.NavigationControl({
      'showZoom': false
    });
    _map.addControl(nav, 'bottom-right');

    _map.on("moveend", () => {
      console.log({
        zoom: _map.getZoom(),
        pitch: _map.getPitch(),
        bearing: _map.getBearing(),
        center: _map.getCenter(),
      });
    });

    _map.on("error", (e) => {
      console.log("Mapbox GL Error", e);
    });

    _map.on("load", () => {

      _map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });

      _map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

      _map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15
        }
      });

      Object.entries(_this.props.sources).forEach(x => {
        const [k, v] = x;
        _map.addSource(k, v);
      });

      _this.props.layers.forEach(x => {
        _map.addLayer(x);
      });

      if(this.props.boundingBox) {
        _map.fitBounds(this.props.boundingBox, {
          padding: 50,
        });
      }
    });

  }

  render() {
    return (
      <div>
        <div ref={(el) => (this.mapContainer = el)} className="map" />
      </div>
    );
  }
}

Map.propTypes = {
  sources: PropTypes.object,
  layers: PropTypes.array,
  boundingBox: PropTypes.array,
  center: PropTypes.array,
  style: PropTypes.string,
  zoom: PropTypes.number,
  pitch: PropTypes.number,
  bearing: PropTypes.number,
};

export default Map;
