import React from "react";
import get from "lodash/get";
import GeoJsonBounds from "geojson-bounds";
import Map from "../../../components/map/map";

import "./style.scss";

import Track from "./track-123020-100127am.json";

class BoundsMap extends React.Component {

  constructor(props) {
    super(props);
    this.bounds = GeoJsonBounds.extent(Track);
  }

  render() {
    return (
      <div className="running-the-richmond-map">
        <div className="map-container">
          <Map
            style="mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y"
            sources={{
              track: { type: "geojson", data: Track },
            }}
            layers={[
              {
                id: "track",
                type: "line",
                source: "track",
                layout: {},
                paint: {
                  "line-color": "#900",
                  "line-width": 3,
                  "line-opacity": 1,
                },
              },
            ]}
            center={[-122.65930156854787, 37.95178230178057]}
            zoom={13}
            pitch={47}
            bearing={-165}
          />
        </div>
      </div>
    );
  }
}

export default BoundsMap;
